import { Helmet } from 'react-helmet';
import AppEntry from './AppEntry';
import { useState, useEffect } from 'react';
import ApkCityAPI from '../services/ApkCityAPI';
import FakeA from './FakeA';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BounceLoader } from "react-spinners";
import Rating from "./Rating";
import slug from "slug";

let apkCityAPI = ApkCityAPI.getInstance();

export default function Search(props) {
    let { term } = useParams();
    console.log(term);

    const [recent, setRecent] = useState([]);
    const [random, setRandom] = useState([]);
    const [searchResult, setSearchResult] = useState(null);

    useEffect(() => {
        (async () => {
            let result = await apkCityAPI.getRecent();
            setRecent(result);
        })();

        (async () => {
            let result = await apkCityAPI.getRandom();
            setRandom(result);
        })();
    }, []);

    useEffect(() => {

        (async () => {
            console.log("searching for: " + term);
            setSearchResult(null);

            let result = await apkCityAPI.searchApp(term);
            setSearchResult(result);

        })();

    }, [term]);

    return (<>
        <Helmet>
            <title>{term} search results - apkzz.com</title>
            <meta name="description" content={term + " search results. Find the app you are looking for on the list and get apk file."} />
        </Helmet>


        <div style={{ backgroundColor: '#F3F3FF' }} className="single-news">
            <div style={{ maxWidth: '1220px' }} className="container">
                <div className="row">

                    <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '30px' }} className="col-lg-8">
                        <div style={{ marginLeft: '10px', marginRight: '10px' }} className="sn-container">
                            <br /><br />
                            {searchResult !== null && searchResult.length > 0 && <> <center><h4><b>Search results for: <font color="62D67C">{term}</font></b></h4></center>
                                <center><p>Find the game or app you are looking for on the list below.</p></center> </>}
                            {searchResult === null && <center><h4><b>Searching for: <font color="62D67C">{term}</font></b></h4></center>}
                            {searchResult !== null && searchResult.length === 0 && <center><h4><b>No search results for: <font color="62D67C">{term}</font></b></h4></center>}


                            <br />
                            <div className="clearfix" />
                            <hr style={{ marginBottom: '25px' }} />


                            {searchResult === null && <>
                                <div style={{ width: '100%', height: '100px', textAlign: 'center', paddingTop: '20px' }}>
                                    <BounceLoader />
                                </div>
                            </>}


                            {searchResult !== null && searchResult.map((elem, idx) => {

                                return <React.Fragment key={idx}>
                                    <FakeA href={'/' + elem.slug + '-apk-update-unlocked'}>
                                        <img style={{ float: 'left', width: '112px', marginRight: '20px' }} src={apkCityAPI.generateIconLinkFromSlug(elem.slug)} />
                                        <h5><b>{elem.title}</b></h5>
                                    </FakeA>
                                    <Rating small score={elem.score}/><br />
                                    Author: <FakeA href={'/'+slug(elem.developer)+'-developer'}><font color="009EFF">{elem.developer}</font></FakeA><br />
                                    <FakeA href={'/' + elem.slug + '-apk-update-unlocked'}><button className="btn" type="submit">Read More</button></FakeA>
                                    <div className="clearfix" />
                                    <br />

                                </React.Fragment>

                            })}

                        </div>
                    </div>

                    <div style={{ backgroundColor: '#F3F3FF' }} className="col-lg-4">
                        <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginLeft: '7px' }} className="sidebar">
                            <div style={{ padding: '10px' }} className="sidebar-widget">
                                <h4>Recent Apps</h4>
                                <div className="news-list">
                                    <div className="clearfix" style={{ clear: 'both' }} />
                                    <br />


                                    {recent.map((app, idx) => {
                                        let short = app.title;
                                        if (short.length > 20) short = `${short.substr(0, 20)}...`;
                                        return (<React.Fragment key={idx}>
                                            <FakeA href={'/' + app.slug + '-apk-update-unlocked'}>
                                                <img style={{ width: '90px', float: 'left', marginRight: '10px', marginLeft: '15px', marginBottom: '20px' }} src={apkCityAPI.generateIconLinkFromSlug(app.slug)}  />
                                            </FakeA>
                                            <FakeA href={'/' + app.slug + '-apk-update-unlocked'}><b><p style={{ fontSize: '17px', marginTop: '10px' }}>{short}</p></b></FakeA>
                                            <p style={{ fontSize: '14px' }}>{app.developer}<br />{(new Date(app.addedDate)).toISOString().split('T')[0]}</p>
                                            <div className="clearfix" style={{ clear: 'both' }} />
                                        </React.Fragment>)
                                    })}

                                </div>
                            </div>
                            <div style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }} className="sidebar-widget">
                                <h4 style={{ marginBottom: '20px' }}>Random</h4>

                                {random.map((elem, idx) => {
                                    return <FakeA key={idx} href={"/" + elem.slug + '-apk-update-unlocked'}><p style={{ fontSize: '17px', marginLeft: '15px', color: '#009EFF' }}>{elem.title}</p></FakeA>;
                                })}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Single News End*/}

    </>)
}
