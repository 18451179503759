import { Helmet } from 'react-helmet'

export default function Terms() {
    return (<>
        <Helmet>
            <title>Terms of Use - apkzz.com</title>
            <meta name="description" content="Follow the rules. All www.apkzz.com downloads are for personal use only, this site is not responsible for the contents of them." />

        </Helmet>
            <div style={{ backgroundColor: '#F3F3FF', paddingTop: '25px' }}>
                <div style={{ maxWidth: '1220px' }} className="container">
                    <div className="row align-items-center">
                        <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '25px' }} className="col-lg-8">
                            <br />
                            <h3>Terms of Use</h3>
                            <br />
                            All www.apkzz.com downloads are for personal use only, this site is not responsible for the contents of them. All downloads are freeware, trial or ad supported, if not, write a mail to communicate the technical-problem and the file will be deleted immediately. The author intended not to use any copyrighted material for the publication or, if not possible, to indicate the copyright of the respective object.
                            <br /><br />
                            www.apkzz.com will not be held liable for any damages as a result of the free apps you download. If there is any problem with this download, aside from functionality or usage of the work, please report this file to webmaster for review. The author reserves the right not to be responsible for the topicality, correctness, completeness or quality of the information provided. Liability claims regarding damage caused by the use of any information provided, including any kind of information which is incomplete or incorrect, will therefore be rejected.
                            <br /><br />
                            www.apkzz.com does not Warrant, Guarantee or make any representation regarding the use, or the results of the use, of applications in terms of Correctness, Accuracy, Reliability, Correctness or otherwise. The entire risk as to the results and performance of the downloads is assumed by the end user. Apkzz.com shall not be liable to end user for any consequential or incidental damages arising from these free Apps provided on our site. You are downloading Applications at your own free will, and that you assume any and all responsibilities incorporated in doing so.
                            <br /><br />
                        </div>
                        <div style={{ marginBottom: '25px' }} className="col-lg-4">
                            <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginLeft: '7px' }}>
                                <br />
                                <center>
                                    <img alt=""  style={{ maxWidth: '100%' }} src="img/terms.png" />
                                </center>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </>);
}