import { Helmet } from 'react-helmet'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ApkCityAPI from "../services/ApkCityAPI";
import { useEffect, useState } from 'react';
import { Button, Carousel } from 'react-bootstrap';
import FakeA from "./FakeA";
import { useHistory } from 'react-router-dom';
import slug from 'slug';
import Pills from "./Pills";
import AppEntry from './AppEntry';
import MySlider from './MySlider';
import AdSense from 'react-adsense';

let apkCityAPI = ApkCityAPI.getInstance();

export default function Home() {
    let [random, setRandom] = useState([]);
    let [randomCategories, setRandomCategories] = useState([]);
    let [randomDevelopers, setRandomDevelopers] = useState([]);
    let [newestApps, setNewestApps] = useState([]);

    let [columns, setColumns] = useState({
        reviews: [],
        random: [],
        downloads: [],

        forum: [],
        threads: [],
        posts: []
    });

    let [bar1, setBar1] = useState("Reviews");
    let [bar2, setBar2] = useState("Forum");

    const history = useHistory();

    useEffect(() => {
        (async () => {
            let result = await apkCityAPI.getRandom();
            setRandom(result);
        })();

        (async () => {
            let result = await apkCityAPI.getRandomCategories();
            setRandomCategories(result);
        })();

        (async () => {
            setRandomDevelopers(await apkCityAPI.getRandomDevelopers());
        })();

        (async () => {
            let c = await apkCityAPI.getColumns();
            setColumns(c);
            //console.log(c);
        })();

        (async () => {
            let result = await apkCityAPI.getNewestApps();
            setNewestApps(result);
        })();

    }, []);

    let settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false
    };

    let slider;
    function next() {
        slider.slickNext();
    }

    let reviewsRows = [];
    let randomReviewsRows = [];
    let downloadsRows = [];

    let forumRows = [];
    let threadsRows = [];
    let postRows = [];

    if (columns.reviews) {
        for (let i = 0; i < columns.reviews.length; i += 2) {
            reviewsRows.push(
                <div className="row">
                    <AppEntry small addUnlocked={true} data={columns.reviews[i]} />
                    <AppEntry small addUnlocked={true}  data={columns.reviews[i + 1]} />
                </div>
            );
        }

        if (columns.random)
            for (let i = 0; i < columns.random.length; i += 2) {
                randomReviewsRows.push(
                    <div className="row">
                        <AppEntry linkAdd="-reviews" small data={columns.random[i]} />
                        <AppEntry linkAdd="-reviews" small data={columns.random[i + 1]} />
                    </div>
                );
            }

        if (columns.downloads)
            for (let i = 0; i < columns.downloads.length; i += 2) {
                downloadsRows.push(
                    <div className="row">
                        <AppEntry linkPre="download-" small data={columns.downloads[i]} />
                        <AppEntry linkPre="download-" small data={columns.downloads[i + 1]} />
                    </div>
                );
            }

        //-----------------

        if (columns.forum)
            for (let i = 0; i < columns.forum.length; i += 2) {
                forumRows.push(
                    <div className="row">
                        <AppEntry linkAdd="-forum" small data={columns.forum[i]} />
                        <AppEntry linkAdd="-forum" small data={columns.forum[i + 1]} />
                    </div>
                );
            }

        if (columns.threads)
            for (let i = 0; i < columns.threads.length; i += 2) {
                threadsRows.push(
                    <div className="row">
                        <AppEntry linkAdd="-thread" small data={columns.threads[i]} />
                        <AppEntry linkAdd="-thread" small data={columns.threads[i + 1]} />
                    </div>
                );
            }

        if (columns.posts)
            for (let i = 0; i < columns.posts.length; i += 2) {
                postRows.push(
                    <div className="row">
                        <AppEntry linkAdd="-post" small data={columns.posts[i]} />
                        <AppEntry linkAdd="-post" small data={columns.posts[i + 1]} />
                    </div>
                );
            }
    }

    return (<>
        <Helmet>
            <title>Update Apk Android Games and Apps Full Versions plus Mods - apkzz.com</title>
            <meta name="description" content="Download apk free for Android devices, find the best apps and games, check updates & user reviews, join forum discussion and get obb file." />
        </Helmet>

        {/* <button onClick={(e)=> {next()} }>next</button>

        <div className='container'>
            <h2> Single Item</h2>
            <Slider 
                ref={c => (slider = c)}

                {...settings}>
                <div>
                    <h3>1</h3>
                </div>
                <div>
                    <h3>2</h3>
                </div>
                <div>
                    <h3>3</h3>
                </div>
                <div>
                    <h3>4</h3>
                </div>
                <div>
                    <h3>5</h3>
                </div>
                <div>
                    <h3>6</h3>
                </div>
            </Slider>
        </div> */}

        <div>
            {/* Top News Start*/}
            <div style={{ backgroundColor: '#F3F3FF' }} className="top-news">
                <div style={{ maxWidth: '1220px' }} className="container">
                    <div className="row">
                        <div className="col-md-8 tn-left">

                            <Carousel
                                indicators={false}
                                nextIcon={<Button style={{ fontWeight: 'bold' }}>&gt;</Button>}
                                prevIcon={<Button style={{ fontWeight: 'bold' }}>&lt;</Button>}
                            >
                                {random.slice(0, 5).map((app, idx) => {
                                    if (app === null) return<></>;
                                    let url = app.screenshots[0];
                                    if (url.search('https') === -1) url = "https:" + url;

                                    return <Carousel.Item
                                        onClick={() => { history.push('/' + app.slug + '-apk-update-unlocked') }}
                                        key={idx} interval={3000} style={{ textAlign: 'center' }}>
                                        <img src={`/api/proxyImage/`+btoa(url) }
                                        
                                        
                                        style={{ height: '39vh' }} />
                                        <div className="tn-title" style={{ textAlign: 'left' }}>
                                            <FakeA href={'/' + app.slug+ '-apk-update-unlocked'}>{app.title}</FakeA>
                                        </div>

                                    </Carousel.Item>;
                                })}
                            </Carousel>
                            <div style={{ backgroundColor: '#F3F3FF' }}>
                                <br />
                            </div>
                        </div>
                        <br />
                        <div className="col-md-4 tn-right">
                            <div className="row">

                                {random.slice(5, 11).map((app, idx) => {
                                    if (app === null) return <></>;
                                    let url = app.screenshots[app.screenshots.length - 1];
                                    if (url !== undefined && url.search('https') === -1) url = "https:" + url;

                                    return <div className="col-md-6" key={idx}>
                                        <div style={{ height: '13vh', textAlign: 'center' }} className="tn-img">
                                            <img alt="" src={`/api/proxyImage/`+btoa(url) } onClick={() => { history.push('/' + app.slug+ '-apk-update-unlocked') }} />
                                            <div className="tn-title" style={{ textAlign: 'left' }} onClick={() => { history.push('/' + app.slug+ '-apk-update-unlocked') }} >
                                                <FakeA href={'/' + app.slug+ '-apk-update-unlocked'}>{app.title}</FakeA>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div style={{ backgroundColor: '#F3F3FF' }} className="cat-news">


            <div style={{textAlign: "center"}}>
                {/* AD 1  */}
                <AdSense.Google
                    client='ca-pub-2691069495400373'
                    slot='7944508129'
                    style={{ display: "inline-block", width: 750, height: 300 }}
                    format=''
                />
                </div>


                <div style={{ maxWidth: '1220px', backgroundColor: '#fff', border: '3px solid #EEEEFE' }} className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <br />
                            <MySlider typetwo genre="Action" />
                        </div>
                        <div className="col-md-6">
                            <br />
                            <MySlider typetwo genre="Strategy" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MySlider typetwo genre="Role Playing" />
                        </div>
                        <div className="col-md-6">
                            <MySlider typetwo genre="Puzzle" />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#F3F3FF' }}>
                <br />
            </div>
            {/* Tab News Start*/}
            <div style={{ backgroundColor: '#F3F3FF' }} className="tab-news">
                <div style={{ maxWidth: '1220px', backgroundColor: '#fff', border: '3px solid #EEEEFE' }} className="container">
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            {/* {bar1} */}
                            <Pills
                                onSelect={(option) => { setBar1(option) }}
                            />

                            {bar1 === "Reviews" && <div style={{ backgroundColor: '#fff' }} className="tab-content">
                                <div id="reviews" className="container tab-pane active">
                                    {reviewsRows}
                                </div>
                            </div>}

                            {bar1 === "Random" && <div style={{ backgroundColor: '#fff' }} className="tab-content">
                                <div id="reviews" className="container tab-pane active">
                                    {randomReviewsRows}
                                </div>
                            </div>}

                            {bar1 === "Download" && <div style={{ backgroundColor: '#fff' }} className="tab-content">
                                <div id="reviews" className="container tab-pane active">
                                    {downloadsRows}
                                </div>
                            </div>}

                        </div>
                        <div className="col-md-6">

                            {/* {bar2} */}
                            <Pills
                                onSelect={(option) => { setBar2(option) }}
                                options={["Forum", "Threads", "Posts"]} />
                            {bar2 === "Forum" && <div style={{ backgroundColor: '#fff' }} className="tab-content">
                                <div id="reviews" className="container tab-pane active">
                                    {forumRows}
                                </div>
                            </div>}

                            {bar2 === "Threads" && <div style={{ backgroundColor: '#fff' }} className="tab-content">
                                <div id="reviews" className="container tab-pane active">
                                    {threadsRows}
                                </div>
                            </div>}

                            {bar2 === "Posts" && <div style={{ backgroundColor: '#fff' }} className="tab-content">
                                <div id="reviews" className="container tab-pane active">
                                    {postRows}
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#F3F3FF' }}>
                <br />


                {/* AD 2  */}
                <div style={{textAlign: "center"}}>
                <AdSense.Google
                    client='ca-pub-2691069495400373'
                    slot='6439854767'
                    style={{ display: "inline-block", width: 750, height: 300 }}
                    format=''
                />
                </div>


            </div>
            <div style={{ backgroundColor: '#F3F3FF' }} className="main-news">
                <div style={{ maxWidth: '1220px', backgroundColor: '#fff', border: '3px solid #EEEEFE' }} className="container">
                    <br />
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="row">

                                {newestApps.slice(0, 18).map((elem, idx) => {

                                    return <div key={idx} className="col-md-2">
                                        <div className="mn-img">
                                            <img alt="" src={apkCityAPI.generateIconLinkFromSlug(elem.slug)} />
                                            <div className="mn-title">
                                                <FakeA href={'/' + elem.slug + '-apk-update-unlocked'}>{elem.title}</FakeA>
                                            </div>
                                        </div>
                                    </div>
                                })}


                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="mn-list">
                                <h4>Categories</h4>
                                <ul>
                                    {randomCategories.map((elem, idx) => {
                                        return <li key={idx} ><FakeA href={'/' + slug(elem) + '-category'}>{elem}</FakeA></li>
                                    })}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Main News End*/}
            <div style={{ backgroundColor: '#F3F3FF' }}>
                <br />
            </div>
            <div style={{ backgroundColor: '#F3F3FF' }} className="cat-news">
                <div style={{ maxWidth: '1220px', backgroundColor: '#fff', border: '3px solid #EEEEFE' }} className="container">
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <MySlider typetwo genre="Sports" />
                        </div>
                        <div className="col-md-6">
                            <MySlider typetwo genre="Adventure" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MySlider typetwo genre="Educational" />
                        </div>
                        <div className="col-md-6">
                            <MySlider typetwo genre="Arcade" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Category News End*/}
            {/* Single News Start*/}
            <div style={{ backgroundColor: '#F3F3FF' }} className="single-news">
                <div style={{ maxWidth: '1220px' }} className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sidebar">
                                <div className="sidebar-widget">
                                    <div className="tags">
                                        {randomDevelopers.map((elem, idx) => {

                                            return <div style={{ marginRight: '5px', display: 'inline-block' }} key={idx} ><FakeA href={'/' + slug(elem) + '-developer'}>{elem}</FakeA></div>
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* AD 3  */}
                <div style={{textAlign: "center"}}>
                <AdSense.Google
                    client='ca-pub-2691069495400373'
                    slot='1187528083'
                    style={{ display: "inline-block", width: 750, height: 300 }}
                    format=''
                />
                </div>
            </div>
            {/* Single News End*/}
        </div>

    </>);
}
