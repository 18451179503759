import { Helmet } from 'react-helmet'

export default function About() {
    return (<>
        <Helmet>
            	
            <title>About us - apkzz.com</title>
            <meta name="description" content="Apkzz.com is a website providing smartphones software downloads founded in 2021 by Apkzz Team, and has grown into one of the leading websites in the smartphones software industry." />

        </Helmet>
        <div style={{ backgroundColor: '#F3F3FF', paddingTop: '25px' }}>
            <div style={{ maxWidth: '1220px' }} className="container">
                <div className="row align-items-center">
                    <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '25px' }} className="col-lg-8">
                        <br />
                        <h3>About</h3>
                        <br />
                        Apkzz.com is a website providing smartphones software downloads founded in 2021 by APKzz Team, and has grown into one of the leading websites in the smartphones software industry. Explore the biggest collection of reviews, tutorials, comparisons, tips and tricks for mobile games &amp; apps. Check forum discussion, app rating, alternatives, detailed information, gallery, get pure apk file and join our community today!
                        <br /><br />
                        Providing a safer, better and faster software download experience for our visitors all over the world. We offer one of the most comprehensive collections of games, apps and history version list. All download works guarantee 100% no extra extension needed. We believe all you guys deserve a much better way to enjoy mobile life. Apkzz team is always ready to help all Android users solve their problems about smartphones app installing, update and more.
                        <br /><br />
                        Apkzz.com is NOT associated or affiliated with Google, Google Play or Android in any way. Android is a trademark of Google Inc. All the games &amp; apps are property and trademark of their respective developer or publisher and for HOME or PERSONAL use ONLY. Please be aware that apkzz.com ONLY SHARE THE ORIGINAL APK FILE FOR FREE APPS. ALL THE APK FILE IS THE SAME AS IN GOOGLE PLAY WITHOUT ANY CHEAT, UNLIMITED RESOURCES PATCH OR ANY OTHER MODIFICATIONS.
                        <br /><br />
                    </div>
                    <div style={{ marginBottom: '25px' }} className="col-lg-4">
                        <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginLeft: '7px' }}>
                            <br />
                            <center>
                                <img alt=""  style={{ maxWidth: '100%' }} src="img/about.png" />
                            </center>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>);
}