import { useState, useEffect } from 'react';
import React from 'react';
import { Button, Carousel } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {Helmet } from 'react-helmet';
import FakeA from './FakeA';
import slug from 'slug';

import Rating from './Rating';
import ApkCityAPI from '../services/ApkCityAPI';
import Review from './Review';
import RecentRandom from './RecentRandom';
import AddReview from './AddReview';
import MySlider from './MySlider';
import AdSense from 'react-adsense';

let apkCityAPI = ApkCityAPI.getInstance();

export default function Result(props) {
    const history = useHistory();
    const [showMore, setShowMore] = useState(false);
    const {appData: app} = props;

    const [index, setIndex] = useState(0);
    const [appCounts, setAppCounts] = useState(null);
    const [showMoreButton, setShowMoreButton] = useState(true);
    const [reviews, setReviews] = useState([]);

    useEffect(()=>{
        setIndex(0);
    }, [app]);

    useEffect(()=> {
        (async ()=> {
            let result = await apkCityAPI.getAppReviews(app.slug, 5);
            setReviews(result.reviews); 
        })();

        (async ()=> {
            let result = await apkCityAPI.getAppCounts(app.slug);
            setAppCounts(result); 
        })();
    }, [app]);

    const ref = React.createRef();

    useEffect(()=> {
        if (ref.current) {
            if (ref.current.clientHeight < 200) 
                setShowMoreButton(false);
            else setShowMoreButton(true);
        }
    }, [ref]);

    let lessStyle = { position: 'relative'};
    if (showMoreButton) {
        lessStyle.height = '200px';
    }

    function onAddReview(review) {
        setReviews( [review, ...reviews.slice(0, 4)] );

        setAppCounts({
            threads: appCounts.threads,
            reviews: appCounts.reviews+1
        });
    }
    
    return (<>

        <Helmet>
            <title>{app.title} Apk Update Unlocked - apkzz.com</title>
            <meta name="description" content={"Download "+app.title+" apk new version for Android device with one click. Get the latest mobile release or check previous ones without registration."} />

        </Helmet>

        <div style={{ backgroundColor: '#F3F3FF' }} className="single-news">
            <div style={{ maxWidth: '1220px' }} className="container">
                <div className="row">
                    <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '30px' }} className="col-lg-8">
                        <div style={{ marginLeft: '10px', marginRight: '10px' }} className="sn-container">
                            <br />
                            <img style={{ width: '165px', float: 'left', marginRight: '20px' }} src={apkCityAPI.generateIconLinkFromSlug(app.slug)} />
                            <h3><b>{app.title} Apk Update Unlocked</b></h3>

                            <Rating score={app.score} />
                            {appCounts && <small><FakeA href={'/'+app.slug+'-reviews'} ><font color="009EFF">{appCounts.reviews} Reviews</font></FakeA> | <FakeA href={'/'+app.slug+'-forum'} ><font color="009EFF">{appCounts.threads} Threads</font></FakeA></small>}

                            <p style={{ fontSize: '16px' }}>Requires Android: {app.androidVersionText}</p>
                            <p style={{ fontSize: '16px' }}><FakeA href={'/'+slug(app.developer)+'-developer'} ><font color="009EFF">{app.developer}</font></FakeA></p>
                            {/* <a href="download.html"><button className="btn" type="submit">Download APK</button></a> */}
                            <div > <FakeA href={`/download-${app.slug}`}> <Button>Download APK</Button> </FakeA></div>
                            <div className="clearfix" style={{ clear: 'both' }} />

                            {/* AD 4 */}
                            <div style={{textAlign: "center", marginTop: '10px'}}>
<AdSense.Google
  client='ca-pub-2691069495400373'
  slot='7713214465'
  style={{ display: "inline-block", width: 336, height: 280}}
  format=''
/>
</div>

                            <hr />

                            { app.screenshots !== undefined && <Carousel 
                                activeIndex={index}
                                onSelect={(idx)=> {setIndex(idx)} }
                                nextIcon={<Button style={{ fontWeight: 'bold' }}>&gt;</Button>}
                                prevIcon={<Button style={{ fontWeight: 'bold' }}>&lt;</Button>}
                            >

                                { app.screenshots.map((screenshoot, idx) => {

                                    let url = screenshoot;
                                    if (url.search('https:') === -1) url = "https" + url;
                                    else if (url.search('https') === -1) url = "https:" + url;

                                    if (url.search('https//') !== -1) {
                                        url = url.replace('https//', 'https://');
                                    } 

                                    return <Carousel.Item key={idx} interval={3000} style={{ textAlign: 'center' }}>
                                        <img src={`/api/proxyImage/`+btoa(url) } style={{ height: '45vh' }} />

                                    </Carousel.Item>;

                                })}


                            </Carousel> }

                            <hr />
                            <div className="sn-content">
							<FakeA href={'/'+app.slug+'-forum'}><button style={{ float: 'right', marginLeft: '5px', marginBottom: '5px' }} className="btn" type="submit">Discussion</button></FakeA>
                                <h5 style={{ marginBottom: '10px' }}><b>{app.title} Apk for Android – read more:</b></h5>	

                                <div style={{textAlign: 'justify', marginBottom: '20px'}}>
                                Wanna test {app.title} on your Android? You’re in the right place! Download {app.title} apk directly to your mobile device and enjoy using this amazing app. This game/app has rating {app.score} and you can leave your opinion about this application on www.apkzz.com right now! Recent update for this file was in {(new Date(app.updatedDate)).toISOString().split('T')[0]}. Please share this with your friends and join gaming community! Google Market claims it has about {app.minInstalls} downloads till today. Need more details? File size is undefined, so please be sure that you’ve enough space on your device. Apkzz.com present popular and legal way to download this aplication for 5.1, this great way doesn’t require surveys or payments, because it breaks rules of {app.developer} which is author of the app/game. Ready for new experience? {app.title} apk awaiting you and your skill, do not wait! The file category is {app.genre} so you like this kind of applications, you must test it! The app is made for {app.contentRating} – sometimes you won’t able to play some apps & games from Google Playstore due to parental restrictions. The current version of this game is prepared for Android {app.androidVersionText} but that could have changed today. You should definitely install {app.title} APK on Android , it’s free & safe. The price? The game is available for free, but feel free to visit the biggest popular android markets to find direct download buttons.
                                </div>	

                         {/* AD 6 */}
                         <div style={{textAlign: "center"}}>
<AdSense.Google
  client='ca-pub-2691069495400373'
  slot='9956234420'
  style={{ display: "inline-block", width: 336, height: 280}}
  format=''
/>
</div>								

                                {!showMore && <>
                                    <div style={lessStyle}>
                                        <div style={{inset:'0px', height: '95%', width:'100%', overflow: 'hidden' }}>
                                            <div ref={ref} dangerouslySetInnerHTML={{ __html: app.descriptionHTML }}></div>

                                            </div>
                                        <div style={{position: 'absolute', left:'0px', right: '0px', bottom: '0px', top: '0px', backgroundImage: 'url("/img/transparent.png")'}}></div>
                                    </div>
                                <br />
                                    
                                    {showMoreButton && <span className="clickable" onClick={()=>{setShowMore(true); }} ><font color="009EFF">Show More ↓</font></span>}
                                </>}

                                {showMore && <>
                                    <div style={{ position: 'relative' }}>
                                        <div style={{width: '100%'}} dangerouslySetInnerHTML={{ __html: app.descriptionHTML }}></div>
                                    </div>
                                <br />
                                    
                                    {showMoreButton && <span className="clickable" onClick={()=>{setShowMore(false); }} ><font color="009EFF">Show Less ↑</font></span>}
                                </>}



                                <hr />




                                <h5 style={{ marginBottom: '10px' }}><b>Details</b> </h5>
                                <div style={{ overflowX: 'auto' }}>
                                    <table style={{ width: '100%' }}>
                                        <tbody><tr>
                                            {app.genre !== undefined && <td>Category: <FakeA href={'/'+slug(app.genre.toLowerCase())+'-category'} ><font color="009EFF">{app.genre}</font></FakeA></td>}

                                            <td>Updated: {(new Date(app.updatedDate)).toISOString().split('T')[0]}</td>
                                            {app.maxInstalls && <td>Installs: {app.maxInstalls.toLocaleString()}+</td>}
                                            {!app.maxInstalls && app.minInstalls && <td>Installs: {app.minInstalls.toLocaleString()}+</td>}
                                        </tr>
                                            <tr>
                                                <td>Requires Android: {app.androidVersionText}</td>
                                                <td>Author: <FakeA href={'/'+slug(app.developer)+'-developer'} ><font color="009EFF">{app.developer}</font></FakeA></td>
                                                <td>File: {app.appID}</td>
                                            </tr>
                                            <tr>
                                                <td>Name: {app.title}</td>
                                                <td>Content Rating: {app.contentRating}</td>
                                                <td>Star Rating: {app.score}</td>
                                            </tr>
                                        </tbody></table>
                                </div>
                                <hr />
								You can download games and apps from two sources. Let’s find {app.title} {app.androidVersionText} for Android in google store or simply choose free openload mirror.
								<br/><br/>
                                <center>
                                    <a href={"https://play.google.com/store/apps/details?id="+app.appID} rel="nofollow" target="_blank"><img src="/img/apk.png" /></a>
                                </center>
								<br/>
								Do not wait and download {app.title} APK for Android, you can scan it before installation if you wish. 


<div style={{marginBottom: '15px'}}></div>
{/* AD 5 */}
<div style={{textAlign: "center"}}>
<AdSense.Google
  client='ca-pub-2691069495400373'
  slot='8834724447'
  style={{ display: "inline-block", width: 336, height: 280}}
  format=''
/>
</div>								
								

                                <div style={{textAlign: 'justify', marginBottom: '20px'}}>

                                If you don’t know how to get {app.title} latest version for ios android, we will try to help you. Please notice that all we deliver is legal and 100% safe, you can’t download suspicious staff here. Apkzz.com deliver fresh & safe apk games and apps for android only. You can simply add your own reviews or tips for {app.developer} app or share own guides that makes the gameplay easier and gives more fun. We really appreciate it! Well, if you like playing games and using apps from {app.genre} category, you should definitely check this out! Take a look at PEGI review, this apk file rating is {app.contentRating}. Download {app.appID} unlocked on Android and have fun. {app.title} recent update is ready to run on {app.androidVersionText} and the file is very popular, because it has {app.minInstalls} downloads right now. Sounds good? Player must notice that current version of this game is for Android {app.androidVersionText} and the latest update was in {(new Date(app.updatedDate)).toISOString().split('T')[0]}. Get {app.title} full version unblocked from mirrors above or just receive it directly from google store.
								<br/><br/>
Tags:<br/>							
{app.title} apk full version<br/>
{app.title} update ios android<br/>
{app.title} install apk file<br/>
{app.title} unlocked app<br/>
{app.title} all versions	

                                </div>
                                <hr />
                                <h5 style={{ marginBottom: '20px' }}><b>Reviews</b> 
                                
                                <AddReview app={app} onAdd={onAddReview} />
                                
                                </h5>
                                
                                {reviews.map((elem, idx)=> {
                                    return <Review data={elem} key={idx} />;
                                })}
                                
                                <FakeA href={'/'+app.slug+'-reviews'} ><button style={{ width: '100%' }} className="btn" type="submit">See All Reviews</button></FakeA>
                                <br /><br />
                            </div>
                        </div>
                         



                         <div style={{margin: '10px'}}>
                        {app.genre !== undefined && <MySlider nobuttons genre={app.genre}></MySlider>}
                        </div>
                    </div>
                    
                    <RecentRandom />
                </div>
            </div>
        </div>

    </>);
}
