import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ApkCityAPI from '../services/ApkCityAPI';
import Review from './Review';
import RecentRandom from './RecentRandom';
import slug from 'slug';
import Rating from './Rating';
import FakeA from './FakeA';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";
import { beautifyText } from "./Misc";
import Alert from 'react-bootstrap/Alert';
import AdSense from 'react-adsense';

let apkCityAPI = ApkCityAPI.getInstance();

let wwwregexp = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gm;


export default function Thread(props) {
    let thread = props.threadData;
    let [addingReply, setAddingReply] = useState(false);
    let [content, setContent] = useState("");
    let [captcha, setCaptcha] = useState("");
    let [myAlert, setAlert] = useState("");

    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView();


    async function submitReply() {
        let con = beautifyText(content.trim());

        if (content.search(wwwregexp) !== -1) {
            alert('You can\'t use URL in content!');
            return;
        }

        if (con === '') {
            alert('Content is required!');
            return;
        }

        if (!captcha) {
            alert('Solve captcha!');
            return;
        }

        let body = {
            'g-recaptcha-response': captcha,
            content: con,
            slug: thread.slug,
        }

        let result = await apkCityAPI.savePost(body);
        // if (result.result === 'success') {
        //     al
        // }

        if (result.result === 'success') {
            thread.posts.push({
                addedDate: new Date(),
                text: con
            });

            setAddingReply(false);
            setAlert("Reply was posted successfully!");
        }
    }

    return (<>
        <Helmet>
            <title>{thread.subject} {thread.appId.title} - apkzz.com</title>
            <meta name="description" content={thread.subject + ' ' + thread.appId.title + ' forum thread. Join the discussion, answer the question, share your knowledge.'} />

        </Helmet>
        <div style={{ backgroundColor: '#F3F3FF' }} className="single-news">
            <div style={{ maxWidth: '1220px' }} className="container">
                <div className="row">
                    <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '30px' }} className="col-lg-8">
                        <div style={{ marginLeft: '10px', marginRight: '10px' }} className="sn-container">
                            <br />
                            <FakeA href={'/' + thread.appId.slug+ '-apk-update-unlocked'}>
                                <img style={{ width: '66px', float: 'left', marginRight: '20px' }}
                                
                                src={`/api/icon/${thread.appId.slug}/image.png`} 
                                />
                            </FakeA>
                            <h6><b><FakeA href={'/' + thread.appId.slug+ '-apk-update-unlocked'}>{thread.appId.title}</FakeA></b> Discussion</h6>
                            <p style={{ fontSize: '16px' }}>Forum Thread</p>
                            <p style={{ fontSize: '16px' }}><FakeA href={'/' + thread.appId.slug + '-forum'}><font color="009EFF">Back to General Discussion</font></FakeA></p>
                            <div className="clearfix" />

{/* AD 4 */}
<div style={{textAlign: "center"}}>
    <AdSense.Google
    client='ca-pub-2691069495400373'
    slot='7713214465'
    style={{ display: "inline-block", width: 336, height: 280}}
    format=''
    />
</div>

                            <hr style={{ marginBottom: '15px' }} />
                            <h5><b><FakeA href={'/' + thread.appId.slug + '-forum'}>{thread.appId.title} Forum › </FakeA><font color="62D67C">{thread.subject}</font></b></h5>
                            <button
                                onClick={() => { setAddingReply(true); setAlert(""); executeScroll(); setContent(""); }}
                                style={{ float: 'right' }} className="btn" type="submit">Reply</button>
                            <br /><br />




                            {thread.posts.map((post, idx) => {


                                return <React.Fragment key={idx}>
                                    <img src="img/review.png" style={{ float: 'left', marginRight: '10px' }} />
                                    <div style={{ backgroundColor: '#F3F3FF', marginLeft: '47px', padding: '12px', minHeight: '10vh' }}>
                                        <small style={{ float: 'right' }}><font color={929191}>{(new Date(post.addedDate)).toISOString().split('T')[0]}</font></small>
                                        {post.text}
                                    </div>
                                    <br />
                                </React.Fragment>
                            })}

                            <div ref={myRef}></div>

                            {myAlert !== '' && <Alert variant="primary">
                                {myAlert}
                            </Alert>}

                            {addingReply && <div style={{ paddingTop: '5px' }}>
                                <div className="container">
                                    <div className="row align-items-center" style={{ backgroundColor: '#fff', marginBottom: '25px' }}>
                                        <br />
                                        <h5><b>Post Reply</b></h5>
                                        <br />
                                        <textarea value={content} onChange={(e) => { setContent(e.target.value); }} className="form-control" rows={5} placeholder="Content..." />
                                        <br />
                                        <center>
                                            <center> <ReCAPTCHA
                                                style={{ margin: '5px' }}
                                                sitekey="6Lf1GssbAAAAAIJpy_blj-luckLgFG0CK7L6jE9V"
                                                onChange={(value) => { setCaptcha(value); }}
                                            /> </center>

                                            <button
                                                onClick={(e) => { submitReply(); }}
                                                className="btn" type="submit">Submit</button>
                                        </center>
                                        <br />
                                    </div>
                                </div>
                            </div>}



{/* AD 5 */}
<div style={{textAlign: "center"}}>
<AdSense.Google
  client='ca-pub-2691069495400373'
  slot='8834724447'
  style={{ display: "inline-block", width: 336, height: 280}}
  format=''
/>
</div>


                        </div>
                        <br />
                    </div>
                    <RecentRandom />
                </div>
            </div>
        </div>

    </>);
}