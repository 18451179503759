import { Switch, Route } from "react-router-dom";
import Archive from "./Archive";
import Contact from "./Contact";
import DMCA from "./DMCA";
import Privacy from "./Privacy";
import Terms from "./Terms";
import About from "./About";
import Home from "./Home";
import Identify from "./Identify";
import Error404 from "./Error404";
import Search from "./Search";

export default function PageContent() {

    return (<>
        
        <Switch>
            <Route exact path="/contact">
                <Contact  />
            </Route>
            <Route exact path="/dmca">
                <DMCA />
            </Route>
            <Route exact path="/privacy">
                <Privacy />
            </Route>
            <Route exact path="/terms">
                <Terms />
            </Route>
            <Route exact path="/archive/:pageNumber">
                <Archive />                
            </Route>
            <Route exact path="/search/:term+">
                <Search />                
            </Route>
            <Route exact path="/archive">
                <Archive />                
            </Route>
            
            <Route exact path="/404">
                <Error404 />
            </Route>
            <Route exact path="/about">
                <About />
            </Route>

            <Route path="/:term+">
                <Identify />
            </Route>

            {/* <Route path="/:term/:pageNumber">
                <Identify />
            </Route> */}

            <Route path="/">
                <Home />
            </Route>

        </Switch>
    </>);

}
