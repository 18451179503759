class ApkCityAPI {
    static myInstance = null;
    isLogged = false;
    refreshTimeout = null;

    static getInstance() {
        if (ApkCityAPI.myInstance === null) {
            ApkCityAPI.myInstance = new ApkCityAPI();
        }

        return this.myInstance;
    }

    generateIconLinkFromSlug(slug) {
        return `/icons2/${slug[0] }/${slug[1] }/${slug[2] }/${slug}.jpeg`;

    }

    async search(term) {
        let res = await fetch (`/api/search/${encodeURIComponent(term)}`);
        let json = await res.json();
        return json;
    }

    async searchDeveloper(term) {
        let res = await fetch (`/api/searchDeveloper/${encodeURIComponent(term)}`);
        let json = await res.json();
        return json;
    }

    async searchThread(term) {
        let res = await fetch (`/api/searchThread/${encodeURIComponent(term)}`);
        let json = await res.json();
        return json;
    }

    async searchCategory(term) {
        let res = await fetch (`/api/searchCategory/${encodeURIComponent(term)}`);
        let json = await res.json();
        return json;
    }

    async searchForum(term) {
        let res = await fetch (`/api/searchForum/${encodeURIComponent(term)}`);
        let json = await res.json();
        return json;
    }

    async getRecent() {
        let res = await fetch (`/api/recent`);
        let json = await res.json();
        return json;
    }

    async getAppCounts(slug) {
        let res = await fetch (`/api/getAppCounts/${slug}`);
        let json = await res.json();
        return json;
    }

    async getRandom() {
        let res = await fetch (`/api/random`);
        let json = await res.json();
        return json;
    }

    async getCategoryNewest(slug) {
        let res = await fetch (`/api/getCategoryNewest/${slug}`);
        let json = await res.json();
        return json;
    }

    async getRandomCategories() {
        let res = await fetch (`/api/randomCategories`);
        let json = await res.json();
        return json;
    }

    async getNewestApps() {
        let res = await fetch (`/api/getNewestApps`);
        let json = await res.json();
        return json;
    }

    async getRandomDevelopers() {
        let res = await fetch (`/api/randomDevelopers`);
        let json = await res.json();
        return json;
    }

    async getAppCount() {
        let res = await fetch (`/api/getAppCount`);
        let json = await res.json();
        return json;
    }

    async searchApp(term) {
        let res = await fetch (`/api/searchApp/${encodeURIComponent(term)}`);
        let json = await res.json();
        return json;
    }

    async getAppsPage(skip, limit) {
        let res = await fetch (`/api/getApps/${skip}/${limit}`);
        let json = await res.json();
        return json;
    }

    async getAppsCategoryPage(category, skip, limit) {
        let res = await fetch (`/api/getAppsCategory/${category}/${skip}/${limit}`);
        let json = await res.json();
        return json;
    }

    async getAppReviews(slug, count) {
        let res = await fetch(`/api/reviews/${count}/${slug}`);
        let json = await res.json();
        return json;
    }

    async getColumns(slug, count) {
        let res = await fetch(`/api/columns`);
        let json = await res.json();
        return json;
    }
    

    async saveThread(body) {  
        const response = await fetch(`/api/saveThread`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async savePost(body) {  
        const response = await fetch(`/api/savePost`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async saveReview(body) {  
        const response = await fetch(`/api/saveReview`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }
}

export default ApkCityAPI;