import { Helmet } from 'react-helmet';
import AppEntry from './AppEntry';
import { useState, useEffect } from 'react';
import ApkCityAPI from '../services/ApkCityAPI';
import FakeA from './FakeA';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BounceLoader } from "react-spinners";
import Rating from "./Rating";
import slug from "slug";
import RecentRandom from './RecentRandom';
import Paginator from './Paginator';
import {useHistory} from 'react-router-dom';
import AdSense from 'react-adsense';

let apkCityAPI = ApkCityAPI.getInstance();

const perPage = 20;

export default function Category(props) {
    const history = useHistory();
    let { categoryName } = props.data;

    const params = useParams();
    console.log(`params: ${JSON.stringify(params)}`);

    let term = params.term;
    const pageNumber = params.term.indexOf('/')!==-1? params.term.split('/')[1]: null;

    let count = props.data.count!== null? props.data.count: 5000;

    let [appCount, setAppCount] = useState( count );
    let [pageCount, setPageCount] = useState( count/perPage );
    let [currentPage, setCurrentPage] = useState(pageNumber !== null ? pageNumber : 1);
    let [entries, setEntries] = useState(null);

    useEffect(() => {
        if (pageNumber) {
            setCurrentPage(pageNumber);
        }
    }, [pageNumber]);

    function onPageSelect(page) {
        history.push('/'+term.split('-category')[0] + '-category' +'/'+page);
    }

    useEffect(() => {
        (async () => {
            //let res = await apkCityAPI.getAppCount();
            //setAppCount(res.appCount);
            //setAppCount(50000);
            //setPageCount(res.appCount / perPage);
            //setPageCount(50000 / perPage);
            //console.log(res);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setEntries(null);
            let res = await apkCityAPI.getAppsCategoryPage(term.split('-category')[0], (currentPage - 1) * perPage, perPage);
            setEntries(res.result);

        })();

    }, [currentPage]);

    return (<>
        <Helmet>
            <title>Category: {props.data.categoryName} - apkzz.com</title>
            <meta name="description" content={"Full list of updated games and apps in " + props.data.categoryName + ": strategy. Discover tons of great applications for Android and download APK."} />

        </Helmet>
        <div style={{ backgroundColor: '#F3F3FF' }} className="single-news">
            <div style={{ maxWidth: '1220px' }} className="container">
                <div className="row">
                    <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '30px' }} className="col-lg-8">
                        <div style={{ marginLeft: '10px', marginRight: '10px' }} className="sn-container">
                            <br /><br />
                            <center><h3><b>{props.data.categoryName} </b></h3></center>
                            <center><p>All apps and games in category: <b><font color="62D67C">{props.data.categoryName.toLowerCase()}</font></b></p></center>
                            <br />
                            <div className="clearfix" />

                            {/* AD 4 */}
<div style={{textAlign: "center"}}>
    <AdSense.Google
    client='ca-pub-2691069495400373'
    slot='7713214465'
    style={{ display: "inline-block", width: 336, height: 280}}
    format=''
    />
</div>


                            <hr style={{ marginBottom: '25px' }} />
                            <div className="row">

                                {entries === null && <>
                                <div style={{ width: '100%', height: '100px', textAlign: 'center', paddingTop: '20px' }}>
                                    <BounceLoader />
                                </div>
                            </>}

                                {entries !== null && entries.map((app, idx) => {

                                    return <div key={idx} className="col-md-6">
                                        <FakeA href={'/'+app.slug + '-apk-update-unlocked'}>
                                            <img style={{ float: 'left', width: '117px', marginRight: '20px' }} src={apkCityAPI.generateIconLinkFromSlug(app.slug)} />
                                            <h6><b>{app.title}</b></h6>
                                        </FakeA>
                                        <Rating score={app.score} />
                                        <p style={{margin: '0px', marginTop: '0px'}}><small>Updated: {(new Date(app.updatedDate)).toISOString().split('T')[0]}</small></p>
                                        <FakeA href={'/'+app.slug+'-forum'}><button style={{ margin: '5px' }} className="btn" type="submit">Forum</button></FakeA>
                                        <FakeA href={'/'+app.slug+'-reviews'}><button className="btn" type="submit">Reviews</button></FakeA>
                                        <div className="clearfix" />
                                        <br />
                                    </div>


                                })}

                            </div>
                            <br />
                            <center>
                            <Paginator 
                                prelink={`/${term.split('-category')[0]+'-category'}/`}
                                onPageSelect={onPageSelect}
                                pageCount={pageCount} currentPage={currentPage} />
                            <br /><br />

                            {/* AD 5 */}
<div style={{textAlign: "center"}}>
<AdSense.Google
  client='ca-pub-2691069495400373'
  slot='8834724447'
  style={{ display: "inline-block", width: 336, height: 280}}
  format=''
/>
</div>

                            </center>
                            <div className="clearfix" />
                            <br />
                        </div>
                    </div>
                    <RecentRandom />
                </div>
            </div>
        </div>
        {/* Single News End*/}

    </>)
}
