import {Helmet} from 'react-helmet';
import AppEntry from './AppEntry';
import { useState, useEffect } from 'react';
import ApkCityAPI from '../services/ApkCityAPI';
import FakeA from './FakeA';
import React from 'react';
import AdSense from 'react-adsense';

let apkCityAPI = ApkCityAPI.getInstance();

export default function Developer(props) {
    const [recent, setRecent] = useState([]);
    const [random, setRandom] = useState([]);

    useEffect(()=> {
        (async ()=> {
            let result = await apkCityAPI.getRecent();
            setRecent(result);
        })();

        (async ()=> {
            let result = await apkCityAPI.getRandom();
            setRandom(result);
        })();
    }, []);

    return (<>
        <Helmet>
            <title>{props.developerData.name} Apps and Games - apkzz.com</title>
            <meta name="description" content={"All apps and games developed by " + props.developerData.name + " collected in one place. Visit author page to see complete list of its products."} />

        </Helmet>

        {/* developerData */}

        <div style={{ backgroundColor: '#F3F3FF' }} className="single-news">
            <div style={{ maxWidth: '1220px' }} className="container">
                <div className="row">
                    <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '30px' }} className="col-lg-8">
                        <div style={{ marginLeft: '10px', marginRight: '10px' }} className="sn-container">
                            <br /><br />
                            <center><h3><b>{props.developerData.name}</b></h3></center>
                            <center><p>All apps and games made by {props.developerData.name}.</p></center>
                            <br />
                            <div className="clearfix" />

{/* AD 4 */}
<div style={{textAlign: "center"}}>
    <AdSense.Google
    client='ca-pub-2691069495400373'
    slot='7713214465'
    style={{ display: "inline-block", width: 336, height: 280}}
    format=''
    />
</div>

                            <hr style={{ marginBottom: '25px' }} />
                            

                            {props.developerData.apps.map((elem,idx)=> {

                                return <React.Fragment key={idx}> <AppEntry addUnlocked={true} data={elem} /> <p style={{clear: "both", margin: "0px"}}></p> </React.Fragment>
                            })}


                        </div>

                          {/* AD 5 */}
<div style={{textAlign: "center"}}>
<AdSense.Google
  client='ca-pub-2691069495400373'
  slot='8834724447'
  style={{ display: "inline-block", width: 336, height: 280}}
  format=''
/>
</div>

                    </div>
                    <div style={{ backgroundColor: '#F3F3FF' }} className="col-lg-4">
                        <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginLeft: '7px' }} className="sidebar">
                            <div style={{ padding: '10px' }} className="sidebar-widget">
                                <h4>Recent Apps</h4>
                                <div className="news-list">
                                    <div className="clearfix" style={{ clear: 'both' }} />
                                    <br />


                                    {recent.map((app, idx)=> {
                                        let short = app.title;
                                        if (short.length > 20) short = `${short.substr(0,20)}...`;
                                        return (<React.Fragment key={idx}>
                                        <FakeA href={'/'+app.slug+ '-apk-update-unlocked'}>
                                            <img style={{ width: '90px', float: 'left', marginRight: '10px', marginLeft: '15px', marginBottom: '20px' }} src={apkCityAPI.generateIconLinkFromSlug(app.slug)} />
                                        </FakeA>
                                        <FakeA href={'/'+app.slug+ '-apk-update-unlocked'}><b><p style={{ fontSize: '17px', marginTop: '10px' }}>{short}</p></b></FakeA>
                                        <p style={{ fontSize: '14px' }}>{app.developer}<br />{(new Date(app.addedDate)).toISOString().split('T')[0]}</p>
                                        <div className="clearfix" style={{ clear: 'both' }} />
                                        </React.Fragment>)
                                    })}

                                </div>
                            </div>
                            <div style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }} className="sidebar-widget">
                                <h4 style={{ marginBottom: '20px' }}>Random</h4>

                                {random.map((elem, idx)=> {
                                    return <FakeA key={idx} href={"/"+elem.slug+ '-apk-update-unlocked'}><p style={{ fontSize: '17px', marginLeft: '15px', color: '#009EFF' }}>{elem.title}</p></FakeA>;
                                })}
                                
                                
                            </div>
                        </div>

                                  

                    </div>
                </div>
            </div>

        </div>
        {/* Single News End*/}

    </>)
}