import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ApkCityAPI from '../services/ApkCityAPI';
import Review from './Review';
import RecentRandom from './RecentRandom';
import slug from 'slug';
import Rating from './Rating';
import FakeA from './FakeA';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";
import { beautifyText } from "./Misc";
import AdSense from 'react-adsense';

let apkCityAPI = ApkCityAPI.getInstance();

let wwwregexp = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gm;


export default function Forum(props) {
    let history = useHistory();
    let { app, threads } = props.forumData;
    let [addingPost, setAddingPost] = useState(false);
    let [captcha, setCaptcha] = useState(null);

    let [subject, setSubject] = useState("");
    let [content, setContent] = useState("");

    async function submitThread() {
        let sub = beautifyText(subject.trim());
        let con = beautifyText(content.trim());

        if (subject.search(wwwregexp) !== -1 || content.search(wwwregexp) !== -1) {
            alert('You can\'t use URL in subject or content!');
            return;
        }


        if (sub === '') {
            alert('Subject is required!');
            return;
        }

        if (con === '') {
            alert('Content is required!');
            return;
        }

        if (!captcha) {
            alert('Solve captcha!');
            return;
        }

        let body = {
            'g-recaptcha-response': captcha,
            subject: sub,
            content: con,
            slug: app.slug,
            appId: app._id
        }

        let result = await apkCityAPI.saveThread(body);
        if (result.result === 'success') {
            history.push('/' + result.slug+ '-apk-update-unlocked');
        }

    }

    return (<>
        <Helmet>
            <title>{app.title} Forum Discussion - apkzz.com</title>
            <meta name="description" content={app.title + " forum discussion, general talk, questions and answers. Join the community today, ask for help or share your ideas."} />


        </Helmet>

        <div style={{ backgroundColor: '#F3F3FF' }} className="single-news">
            <div style={{ maxWidth: '1220px' }} className="container">
                <div className="row">
                    <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '30px' }} className="col-lg-8">
                        <div style={{ marginLeft: '10px', marginRight: '10px' }} className="sn-container">
                            <br />
                            <FakeA href={'/' + app.slug+ '-apk-update-unlocked'}>
                                <img style={{ width: '110px', float: 'left', marginRight: '20px' }} src={apkCityAPI.generateIconLinkFromSlug(app.slug)} />
                            </FakeA>
                            <h5><b><FakeA href={'/' + app.slug + '-apk-update-unlocked'}>{app.title}</FakeA> </b>Forum</h5>
                            <Rating score={app.score} />
                            <p style={{ fontSize: '16px' }}>File: {app.appID}</p>
                            <p style={{ fontSize: '16px' }}><FakeA href={'/' + app.slug+ '-apk-update-unlocked'} ><font color="009EFF">{app.title}</font></FakeA> | <FakeA href={'/download-' + app.slug }><font color="009EFF">Download</font></FakeA> | <FakeA href={'/' + app.slug + '-reviews'}><font color="009EFF">Reviews</font></FakeA></p>
                            <div className="clearfix" />



{/* AD 4 */}
<div style={{textAlign: "center"}}>
    <AdSense.Google
    client='ca-pub-2691069495400373'
    slot='7713214465'
    style={{ display: "inline-block", width: 336, height: 280}}
    format=''
    />
</div>

                            <hr style={{ marginBottom: '15px' }} />

                            <button
                                onClick={() => { setAddingPost(true); }}
                                style={{ float: 'right' }} className="btn" type="submit">Post Thread</button>


                            <h6><b>General Discussion</b></h6>
                            <br /><br />


                            {addingPost && <div style={{ paddingTop: '5px' }}>
                                <div className="container">
                                    <div className="row align-items-center" style={{ backgroundColor: '#fff', marginBottom: '25px' }}>
                                        <br />
                                        <h5><b>Post Thread</b></h5>
                                        <br />
                                        <input value={subject} onChange={(e) => { setSubject(e.target.value); }} className="form-control" placeholder="Subject..." />
                                        <textarea value={content} onChange={(e) => { setContent(e.target.value); }} className="form-control" rows={5} placeholder="Content..." />
                                        <br />
                                        <center>
                                            <center> <ReCAPTCHA
                                                style={{ margin: '5px' }}
                                                sitekey="6Lf1GssbAAAAAIJpy_blj-luckLgFG0CK7L6jE9V"
                                                onChange={(value) => { setCaptcha(value); }}
                                            /> </center>

                                            <button
                                                onClick={(e) => { submitThread(); }}
                                                className="btn" type="submit">Submit</button>
                                        </center>
                                        <br />
                                    </div>
                                </div>
                            </div>}

                            {threads.map((thread, idx) => {
                                return <React.Fragment key={idx}>
                                    <FakeA href={'/'+thread.slug}><h5><b><font color="62D67C">{thread.subject}</font></b></h5></FakeA>
                                    <small><font color={929191}>{(new Date(thread.addedDate)).toISOString().split('T')[0]}</font></small>
                                    <hr style={{ marginBottom: '15px' }} />
                                </React.Fragment>

                            })}

                            {/* AD 5 */}
<div style={{textAlign: "center"}}>
<AdSense.Google
  client='ca-pub-2691069495400373'
  slot='8834724447'
  style={{ display: "inline-block", width: 336, height: 280}}
  format=''
/>
</div>

                        </div>
                        <br />
                    </div>
                    <RecentRandom />
                </div>
            </div>
        </div>
        {/* Single News End*/}




    </>);
}
