export default function Rating(props) {
    let right = (-2+(5-props.score)/5*157) + 'px';

    let a = 'url("/img/rating_zero.png")';
    let b = 'url("/img/rating.png")';
    let w = '157px';
    let h = '35px';


    if (props.small) {
        a= 'url("/img/stars_zero.png")';
        b = 'url("/img/stars.png")';
        w= '110px';
        h='25px';
        right = (-2+(5-props.score)/5*110) + 'px';
    }   
    return (<>
        <div style={{  
            marginBottom: '-10px',            
            backgroundImage: a,
            display: 'inline-block', position:'relative', width: w, height: h}}>
            <div style={{inset: '0px', position: 'absolute', right, backgroundImage: b}}>

            </div>
        </div>
    </>);
}