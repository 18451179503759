
import {
    //Route,
    Link,
    useHistory,
    useLocation
} from "react-router-dom";

function FakeA(props) {
    // const location = useLocation();
    const history = useHistory();

    return (
        <>
         <a className={props.className?props.className:""} href={props.href} onClick={(e)=> {e.preventDefault(); history.push(props.href); } } > {props.children} </a>
        </>
    )
}

export default FakeA;