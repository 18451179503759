import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import FakeA from './FakeA';
import slug from 'slug';
import { useEffect, useState } from 'react';

import ApkCityAPI from '../services/ApkCityAPI';
let apkCityAPI = ApkCityAPI.getInstance();

export default function MySlider(props) {
    let { genre } = props;
    let [result, setResult] = useState([]);

    useEffect(() => {
        (async () => {
            let result = await apkCityAPI.getCategoryNewest(slug(genre));
            // console.log(`genre: ${genre}`);
            // console.log("list: ");
            // console.log(result);
            setResult(result);

        })();

    }, [genre]);

    let slider;
    function next() {
        slider.slickNext();
    }
    function prev() {
        slider.slickPrev();
    }

    let settings = {
        autoplay: false,
        infinite: true,
        dots: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [

            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };


    if (props.nobuttons !== undefined) {
        settings.arrows = false;
    }
    
    return (<>
        <div className="sn-related">
            <h4><FakeA href={"/" + slug(props.genre) + '-category'}>{props.genre} <b><font color="62D67C">››</font></b></FakeA>
                {props.typetwo===undefined && <> <button onClick={(e) => { next(); }} style={{ float: "right", fontWeight: "bold" }} className="btn" aria-label="Previous" type="button" >&gt;</button>
                <button onClick={(e) => { prev(); }} style={{ float: "right", marginRight: '5px', fontWeight: "bold" }} className="btn" aria-label="Previous" type="button" >&lt;</button></>}
            </h4>

            <Slider ref={c => (slider = c)} {...settings} className="row" >

                {result.map((elem, idx) => {

                    let shortTitle = elem.title;
                    if (shortTitle.length>16) {
                        shortTitle = shortTitle.substr(0,16).trim() + '...';
                    }


                    return <div key={idx}>
                        <div className="sn-img" style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                            <center>
                                <FakeA href={'/'+elem.slug+ '-apk-update-unlocked'}>
                                    <img style={{width: "100%"}} src={apkCityAPI.generateIconLinkFromSlug(elem.slug)} />
                                    <b><p style={{ fontSize: '14px' }}>{shortTitle}</p></b>
                                </FakeA>
                            </center>
                        </div>
                    </div>
                })}

            </Slider>
        </div>

    </>);
}
