import { Link } from 'react-router-dom';
import FakeA from './components/FakeA';
import PageContent from './components/PageContent';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

function App() {
	const history = useHistory();
	const [searchTerm, setSearchTerm] = useState("");

	function startSearch() {
		if (searchTerm.trim() != '') {
			history.push('/search/' + searchTerm.trim());
			setSearchTerm('');
		}
	}

	function keyUP(event) {
		if (event.keyCode === 13) {	
			startSearch();
		}
	}

	return (
		<div>
			{/* Brand Start */}
			<div className="brand">
				<div style={{ maxWidth: '1220px' }} className="container">
					<div className="row align-items-center">
						<div className="col-lg-8 col-md-8">
							<div className="b-logo">
								<FakeA href="/">
									<img alt="" src="/img/apkcity.png" />
								</FakeA>
							</div>
						</div>
						<div className="col-lg-4 col-md-4">
							<div className="b-search">
								<input 
									onKeyUp={keyUP}
									value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value); }} type="text" placeholder="Search" />
								<button onClick={(e)=> {startSearch(); }}><i className="fa fa-search" /></button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Brand End */}
			{/* Nav Bar Start */}
			<div className="nav-bar">
				<div style={{ maxWidth: '1220px' }} className="container">
					{/* <nav className="navbar navbar-expand-md bg-dark navbar-dark">
						<a href="#" className="navbar-brand">MENU</a>
						<button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
							<span className="navbar-toggler-icon" />
						</button> */}


						<Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
                            <Navbar.Brand href="#home">MENU</Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="">

                                <Nav.Link as={Link} to='/' className="nav-item nav-link"> <i className="fa fa-home" /> Homepage </Nav.Link>
                                
								<Nav.Link as={Link} to='/about' className="nav-item nav-link"> <i className="fa fa-info-circle" /> About Us </Nav.Link>
                                <Nav.Link as={Link} to='/archive' className="nav-item nav-link"> <i className="fa fa-list-ul" /> Archive </Nav.Link>
                                <Nav.Link as={Link} to='/terms' className="nav-item nav-link"> <i className="fa fa-user-circle" /> Terms of Use </Nav.Link>
                                <Nav.Link as={Link} to='/privacy' className="nav-item nav-link"> <i className="fa fa-paperclip" /> Privacy Policy </Nav.Link>
                                <Nav.Link as={Link} to='/dmca' className="nav-item nav-link"> <i className="fa fa-copyright" /> DMCA </Nav.Link>
                                <Nav.Link as={Link} to='/contact' className="nav-item nav-link"> <i className="fa fa-envelope" /> Contact Us </Nav.Link>
{/*                                 

								<Link to="/about" className="nav-item nav-link"><i className="fa fa-info-circle" /> About Us</Link>
								<Link to="/archive" className="nav-item nav-link"><i className="fa fa-list-ul" /> Archive</Link>
								<Link to="/terms" className="nav-item nav-link"><i className="fa fa-user-circle" /> Terms of Use</Link>
								<Link to="/privacy" className="nav-item nav-link"><i className="fa fa-paperclip" /> Privacy Policy</Link>
								<Link to="/dmca" className="nav-item nav-link"><i className="fa fa-copyright" /> DMCA</Link>
								<Link to="/contact" className="nav-item nav-link"><i className="fa fa-envelope" /> Contact Us</Link> */}
							   

                                </Nav>
                               
                            </Navbar.Collapse>
                            </Navbar>

						{/* <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
							<div className="navbar-nav mr-auto">
								<Link to="/" className="nav-item nav-link"><i className="fa fa-home" /> Homepage</Link>
								<Link to="/about" className="nav-item nav-link"><i className="fa fa-info-circle" /> About Us</Link>
								<Link to="/archive" className="nav-item nav-link"><i className="fa fa-list-ul" /> Archive</Link>
								<Link to="/terms" className="nav-item nav-link"><i className="fa fa-user-circle" /> Terms of Use</Link>
								<Link to="/privacy" className="nav-item nav-link"><i className="fa fa-paperclip" /> Privacy Policy</Link>
								<Link to="/dmca" className="nav-item nav-link"><i className="fa fa-copyright" /> DMCA</Link>
								<Link to="/contact" className="nav-item nav-link"><i className="fa fa-envelope" /> Contact Us</Link>
							</div>
						</div> */}
				</div>
			</div>
			{/* Nav Bar End */}


			<PageContent />

			{/* Footer Start */}
			<div className="footer">
				<div style={{ maxWidth: '1220px' }} className="container">
					<div className="row">
						<div className="col-lg-3 col-md-6">
							<div className="footer-widget">
								<h4 className="title">APKzz.com</h4>
								<div className="newsletter">
									<p>
										The biggest apk database with tons of games and apps for download. Check reviews, updates, forum discussion, fixes, gameplays and much more.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="footer-widget">
								<h4 className="title">Features</h4>
								<ul>
									<font color="white">
										<li>Rankings &amp; Top Lists</li>
										<li>Trailers and Related Videos</li>
										<li>Forum Discussion</li>
										<li>APK + OBB Downloads</li>
										<li>User Reviews &amp; Ratings</li>
									</font>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="footer-widget">
								<h4 className="title">Join us</h4>
								<ul>
									<font color="white">
										<li>Try the Best Apps</li>
										<li>Test Games on Android</li>
										<li>Read Forum Topics</li>
										<li>Write Opinions</li>
										<li>Uncover Developers</li>
									</font>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="footer-widget">
								<h4 className="title">Get in Touch</h4>
								<div className="contact-info">
									<p><i className="fa fa-map-marker" />News Street, NY, USA</p>
									<p><i className="fa fa-envelope" />apkzzdotcom@gmail.com</p>
									<p><i className="fa fa-phone" />unavailable</p>
									<p><i className="fa fa-home" />apkzz.com</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Footer End */}
			{/* Footer Bottom Start */}
			<div className="footer-bottom">
				<div style={{ maxWidth: '1220px' }} className="container">
					<div className="row">
						<div className="col-md-6 copyright">
							<p>Android is a trademark of Google Inc.</p>
						</div>
						<div className="col-md-6 template-by">
							<p>Copyright © { (new Date()).getFullYear()} <font color="white">APKzz.com</font> All Rights Reserved.</p>
						</div>
					</div>
				</div>
			</div>
			{/* Footer Bottom End */}
		</div>
	);
}

export default App;
