import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ApkCityAPI from "../services/ApkCityAPI";
import Error404 from "./Error404";
import Result from "./Result";
import Category from "./Category";
import Developer from "./Developer";
import { BounceLoader } from "react-spinners";
import Reviews from "./Reviews";
import Download from "./Download";
import Forum from "./Forum";
import Thread from "./Thread";

const apkCityAPI = ApkCityAPI.getInstance();

export default function Identify() {
    const history = useHistory();
    const { term: searchTerm } = useParams();
    const [searchResult, setSearchResult] = useState(null);

    useEffect(() => {
        console.log(`will search data for '${searchTerm}'`);
        // console.log ( searchTerm.search(new RegExp('-reviews$', 'g')) );
        setSearchResult(null);

        apkCityAPI.searchThread(searchTerm).then((result) => {

            if (result.type !== '404') setSearchResult(result);
            else {

                console.log('-> will search for app FIRST!');
                (async () => {
                    let result = await apkCityAPI.search(searchTerm);
                    if (result.type !== "404")
                        setSearchResult(result);

                    if (result.type === "404") {

                        if (searchTerm.search(new RegExp('-forum$', 'g')) !== -1) {
                            // console.log('-> will search for forum!');
                            (async () => {
                                let result = await apkCityAPI.searchForum(searchTerm.replace('-forum', ''));
                                // console.log(result);
        
                                if (result.result.app === null)
                                    setSearchResult({ type: '404' });
                                else setSearchResult(result);
                            })();
        
                        } else if (searchTerm.search(new RegExp('-developer$', 'g')) !== -1) {
                            console.log('-> will search for developer!');
                            (async () => {
                                let result = await apkCityAPI.searchDeveloper(searchTerm.replace('-developer', ''));
                                setSearchResult(result);
                            })();
        //
                        } else if (searchTerm.search(new RegExp('-apk-update-unlocked$', 'g')) !== -1) {
                            console.log('-> will search for app!');
                            (async () => {
                                let result = await apkCityAPI.search(searchTerm.replace('-apk-update-unlocked', ''));
                                setSearchResult(result);
                            })();
                        } else if (searchTerm.search(new RegExp('-category', 'g')) !== -1) {
                            console.log('-> will search for category!');
                            (async () => {
                                let result = await apkCityAPI.searchCategory(searchTerm.split('-category')[0]);
                                console.log(result);
                                setSearchResult(result);
                            })();
                        } else if (searchTerm.search(new RegExp('-reviews$', 'g')) !== -1) {
                            console.log('-> will search for reviews!');
                            (async () => {
                                let result = await apkCityAPI.getAppReviews(searchTerm.replace('-reviews', ''), 0);
                                setSearchResult(result);
                            })();
                        } else if (searchTerm.search(new RegExp('^download-', 'g')) !== -1) {
                            console.log('-> will search for app download!');
                            (async () => {
                                let result = await apkCityAPI.search(searchTerm.replace('download-', ''), 0);
        
                                if (result.type === 'app')
                                    result.type = 'download';
        
                                setSearchResult(result);
                            })();
                        } else {
                            console.log('-> will search for app!');
                            (async () => {
                                let result = await apkCityAPI.search(searchTerm);
                                setSearchResult(result);
                            })();
                        }
                    }
                })();


            }


        });

    }, [searchTerm]);

    if (searchResult === null) return (<>
        <div style={{ width: '100%', height: '100px', textAlign: 'center', paddingTop: '20px' }}>
            <BounceLoader />
        </div>
    </>);


    if (searchResult.type === '404') {
        history.push('/404');
        return <></>;
    }
    if (searchResult.type === 'thread') return <Thread threadData={searchResult.result} />
    if (searchResult.type === 'download') return <Download appData={searchResult.result} />

    if (searchResult.type === 'app') return <Result appData={searchResult.result} />
    if (searchResult.type === 'developer') return <Developer developerData={searchResult.result} />
    if (searchResult.type === 'category') return <Category data={searchResult} />
    if (searchResult.type === 'forum') return <Forum forumData={searchResult.result} />

    if (searchResult.reviews) return <Reviews reviewsData={searchResult} />

}