let wulgaryzmy = 
`bitch
bitches
suck
sucks
negro
anal
sex
sexual
fuck
gay
gays
porn
porno
fucked
fucking
tits
ass
asshole	
slut
sluts	
motherfucker
motherfuckers
fucker
fuckers
penetration
fisting
cumshot
cumshots
creampie
drugs
drug
cialis
squirt
squirting	
hentai
gangbang
lesbian
lesbians
masturbation
milf
milfs
orgy
orgies
piss
pissed
pissing
pornstar
pornstars	
ebony
cunt
cunts
nigga
dick
dicks
whore
whores	
blowjob
blowjobs	
bukkake
viagra
kamagra
handjob
footjob
rimming
dildo
dildos	
penis
erectile
vagina
vaginas	
penises
cock
cocks
prick
pricks
niggers
nigger
faggot
faggots
cum
cums
dickhead
dickheads
xrumer
pussy
pussies	
erection
erections	
xevil
orgasm
orgasms
xanax
valium
vicodin
shitty
zennoposter
shit`;

wulgaryzmy = wulgaryzmy.split(/\n/g);
let wwwregexp = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gm;

export const beautifyText = function (txt) {
    //console.log(wulgaryzmy);


    if (txt === undefined) return '';
    
    let parsed = txt;

    for (let vul of wulgaryzmy) {
        let b = vul.charAt(0);

        for (let i=1; i<vul.length-1; i++) {
            b+= '*';
        }

        b+= vul.charAt(vul.length-1);

        parsed = parsed.replace(new RegExp(`\\b${vul}\\b`, 'gi'), b );   
        parsed = parsed.replace(wwwregexp, '[REMOVED URL]');
	    parsed = parsed.replace(new RegExp("&nbsp;", "g"), "");
    }

    return parsed;
}


export const checkText = function (txt) {
    let check = beautifyText(txt);
    if (check == txt) return true; 

    return false;
}

// export default beautifyText;
