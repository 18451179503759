import slug from 'slug';
import FakeA from './FakeA';
import ApkCityAPI from '../services/ApkCityAPI';
let apkCityAPI = ApkCityAPI.getInstance();

export default function AppEntry(props) {

    let linkAdd = props.linkAdd!==undefined?props.linkAdd:"";
    let linkPre = props.linkPre!==undefined?props.linkPre:"";


    let addUnlocked = props.addUnlocked !== undefined?'-apk-update-unlocked':'';

    if (props.data === undefined) return <></>; 

    let short = props.data.title;
    if (short.length>12) short=short.substr(0, 12)+"...";
    let dev = props.data.developer;
    if (dev.length>12) dev=dev.substr(0, 12)+"...";

    let link = '/' +linkPre + props.data.slug + linkAdd;
    if (props.data.additionalLink) 
        link = props.data.additionalLink;

    if (props.small) {
        return (<>
            <div style={{ backgroundColor: '#fff' }} className="col-md-6">
            <FakeA href={link + addUnlocked } >
                    <img alt="" style={{ width: '90px', float: 'left', marginRight: '10px' }} src={apkCityAPI.generateIconLinkFromSlug(props.data.slug)} />
                    </FakeA>
                    <FakeA href={link + addUnlocked}><b><p style={{ fontSize: '17px', marginTop: '10px' }}>{short}</p></b></FakeA>
                <p style={{ fontSize: '14px' }}>{dev}<br />{(new Date(props.data.addedDate)).toISOString().split('T')[0]}</p>
            </div>

        </>);
    } else
        return <>
            <FakeA href={'/' + props.data.slug +  addUnlocked}>
                <img style={{ float: 'left', width: '145px', marginRight: '20px' }} src={apkCityAPI.generateIconLinkFromSlug(props.data.slug)} />
                <h5><b>{props.data.title}</b></h5>
            </FakeA>
            <img src="img/rating.png" /><br />
            {props.data.genre !== undefined && <>Category: <FakeA href={'/' + slug(props.data.genre) + '-category'}><font color="009EFF">{props.data.genre}</font></FakeA><br /></>}
            <small>Updated: {(new Date(props.data.updatedDate)).toISOString().split('T')[0]}</small><br />
            <FakeA href={'/download-' + props.data.slug }><button className="btn" type="submit">Download</button></FakeA>
            <FakeA href={'/' + props.data.slug + addUnlocked}><button className="btn" type="submit">Read More</button></FakeA>
            <div className="clearfix" />
            <br />
        </>;
}
