import Rating from './Rating';

export default function Review(props) {
    return (<>
        <img src={'/api/proxyImage/' + btoa( props.data.userImage) } style={{ maxWidth: '150px', float: 'left', marginRight: '10px' }} />
        
        
            <p style={{margin:'0px', marginRight: '5px', display: 'inline-block'}}> <b>{props.data.userName.trim()!==""?props.data.userName:"User"}</b> <small><font color={929191}>{(new Date(props.data.addedDate)).toISOString().split('T')[0]}</font></small>  </p>

        <div style={{position: "relative", display: 'inline-block', width: '150px'}}>
            <div style={{position: "absolute", top: "-20px"}} >
            <Rating small score={props.data.score} /> <br />
            </div>
        </div>


        {/* <img src='/img/stars.png' /> */}
        <div> {props.data.text}
        </div>

        <p style={{clear: 'both'}} />

        <hr />
    </>);
}