import { useState, useEffect } from 'react';
import FakeA from './FakeA';
import React from 'react';
import ApkCityAPI from '../services/ApkCityAPI';

let apkCityAPI = ApkCityAPI.getInstance();

export default function RecentRandom() {
    const [recent, setRecent] = useState([]);
    const [random, setRandom] = useState([]);

    useEffect(()=> {
        (async ()=> {
            let result = await apkCityAPI.getRecent();
            setRecent(result);
        })();

        (async ()=> {
            let result = await apkCityAPI.getRandom();
            setRandom(result);
        })();
    }, []);

    return <div style={{ backgroundColor: '#F3F3FF' }} className="col-lg-4">
        <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginLeft: '7px' }} className="sidebar">
            <div style={{ padding: '10px' }} className="sidebar-widget">
                <h4>Recent Apps</h4>
                <div className="news-list">
                    <div className="clearfix" style={{ clear: 'both' }} />
                    <br />


                    {recent.map((app, idx) => {
                        let short = app.title;
                        if (short.length > 20) short = `${short.substr(0, 20)}...`;
                        return (<React.Fragment key={idx}>
                            <FakeA href={'/' + app.slug + '-apk-update-unlocked'}>
                                <img style={{ width: '90px', float: 'left', marginRight: '10px', marginLeft: '15px', marginBottom: '20px' }} src={apkCityAPI.generateIconLinkFromSlug(app.slug)} />
                            </FakeA>
                            <FakeA href={'/' + app.slug+ '-apk-update-unlocked'}><b><p style={{ fontSize: '17px', marginTop: '10px' }}>{short}</p></b></FakeA>
                            <p style={{ fontSize: '14px' }}>{app.developer}<br />{(new Date(app.addedDate)).toISOString().split('T')[0]}</p>
                            <div className="clearfix" style={{ clear: 'both' }} />
                        </React.Fragment>)
                    })}

                </div>
            </div>
            <div style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }} className="sidebar-widget">
                <h4 style={{ marginBottom: '20px' }}>Random</h4>

                {random.map((elem, idx) => {
                    return <FakeA key={idx} href={"/" + elem.slug + '-apk-update-unlocked'}><p style={{ fontSize: '17px', marginLeft: '15px', color: '#009EFF' }}>{elem.title}</p></FakeA>;
                })}


            </div>
        </div>
    </div>;
}
