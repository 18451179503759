import { useState, useEffect } from 'react';
import ApkCityAPI from '../services/ApkCityAPI';
import Review from './Review';
import RecentRandom from './RecentRandom';
import slug from 'slug';
import Rating from './Rating';
import FakeA from './FakeA';
import { Helmet } from 'react-helmet';
import MySlider from './MySlider';
import AddReview from './AddReview';
import AdSense from 'react-adsense';


let apkCityAPI = ApkCityAPI.getInstance();

export default function Download(props) {
    const {appData: app} = props;
    const [reviews, setReviews] = useState([]);
    useEffect(()=> {
        (async ()=> {
            let result = await apkCityAPI.getAppReviews(app.slug, 5);
            console.log(result);
            setReviews(result.reviews); 
        })();
    }, [app]);


    function onAddReview(review) {
        setReviews( [review, ...reviews] );

        // setAppCounts({
        //     threads: appCounts.threads,
        //     reviews: appCounts.reviews+1
        // });
    }

    return (<>
        <Helmet>
            <title>Download {app.title} - apkzz.com</title>
            <meta name="description" content={app.title + " download apk file for Android system. Install the updated version, see details, read reviews and join the discussion."} />
        </Helmet>

        <div style={{ backgroundColor: '#F3F3FF' }} className="single-news">
            <div style={{ maxWidth: '1220px' }} className="container">
                <div className="row">
                    <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '30px' }} className="col-lg-8">
                        <div style={{ marginLeft: '10px', marginRight: '10px' }} className="sn-container">
                            <br /><br />
                            <center><h3><b>Download {app.title}</b></h3></center>
                            <center><p>Do not wait and get clean Apk application from mirrors given below. Come back for more games and apps! You can download file for Android from Google Play Market or directly from the host, all safe and free.</p></center>
                            <br />
                            <center>
                                <a href={"/api/download/" + app.appID } rel="nofollow" target="_blank"><button className="btn" type="submit">Download Now</button></a>
                            </center>
                            <br />
                            <center>
                                Name: <FakeA href={'/'+slug(app.slug) + '-apk-update-unlocked'} > <font color="009EFF">{app.title}</font></FakeA><br />
                                File: {app.appID}<br />
                                Author: <FakeA href={'/'+slug(app.developer)+'-developer'} ><font color="009EFF">{app.developer}</font></FakeA><br />
                                Requires Android: {app.androidVersionText}
                            </center>
                            <br /><br />
                            <div className="clearfix" />

                            {/* AD 4 */}
                            <div style={{textAlign: "center"}}>
<AdSense.Google
  client='ca-pub-2691069495400373'
  slot='7713214465'
  style={{ display: "inline-block", width: 336, height: 280}}
  format=''
/>
</div>

                            <hr />
                            
                            {app.genre !== undefined && <MySlider nobuttons genre={app.genre}></MySlider>}

                            <hr />
                            <div className="sn-content">
                            <h5 style={{ marginBottom: '20px' }}><b>{reviews.length>0?"Reviews":"No reviews"}</b> <AddReview app={app} onAdd={onAddReview} /> </h5>
                                
                                {reviews.map((elem, idx)=> {
                                    return <Review data={elem} key={idx} />;
                                })}



                                <FakeA href={'/' + app.slug + '-reviews'} ><button style={{ width: '100%' }} className="btn" type="submit">See All Reviews</button></FakeA>
                                <br /><br />


                                {/* AD 5 */}
                                <div style={{textAlign: "center"}}>
<AdSense.Google
  client='ca-pub-2691069495400373'
  slot='8834724447'
  style={{ display: "inline-block", width: 336, height: 280}}
  format=''
/>
</div>


                            </div>
                        </div>
                    </div>
                    <RecentRandom />
                </div>
            </div>
        </div>


    </>);
}