import {useState} from 'react';
import Rating from './Rating';
import RecentRandom from './RecentRandom';
import Review from './Review';
import FakeA from './FakeA';
import { Helmet } from 'react-helmet';
import AddReview from './AddReview';
import AdSense from 'react-adsense';

export default function Reviews(props) {

    let [reviews, setReviews] = useState([]);

    function onAddReview(review) {
        setReviews( [review, ...reviews] );
    }

    return (<>
        <Helmet>
		
        <title>{props.reviewsData.app.title} Reviews and User Rating - apkzz.com</title>
        <meta name="description" content={props.reviewsData.app.title + " reviews, suggestions and opinions, user rating, pros & cons, hidden features, possibilities, tips and much more."} />

        </Helmet>


        {/* Breadcrumb Start */}
        {/* Breadcrumb End */}
        {/* Single News Start*/}
        <div style={{ backgroundColor: '#F3F3FF' }} className="single-news">
            <div style={{ maxWidth: '1220px' }} className="container">
                <div className="row">
                    <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '30px' }} className="col-lg-8">
                        <div style={{ marginLeft: '10px', marginRight: '10px' }} className="sn-container">
                            <br />
                            <FakeA href={'/'+props.reviewsData.app.slug + '-apk-update-unlocked'}>
                                <img style={{ width: '113px', float: 'left', marginRight: '20px' }} src={`/api/icon/${props.reviewsData.app.slug}/image.png`} />
                            </FakeA>


                            <h4><b><FakeA href={'/'+props.reviewsData.app.slug + '-apk-update-unlocked' }>{props.reviewsData.app.title}</FakeA></b> Reviews</h4>
                            <Rating score={props.reviewsData.app.score} />
                            <p style={{ fontSize: '16px' }}>Content Rating: {props.reviewsData.app.contentRating }</p>
                            <p style={{ fontSize: '16px' }}><FakeA href={'/'+props.reviewsData.app.slug + '-apk-update-unlocked'}><font color="009EFF">{props.reviewsData.app.title}</font></FakeA> | <FakeA href={'/download-'+props.reviewsData.app.slug }><font color="009EFF">Download</font></FakeA></p>
                            <div className="clearfix" />



{/* AD 4 */}
<div style={{textAlign: "center"}}>
<AdSense.Google
  client='ca-pub-2691069495400373'
  slot='7713214465'
  style={{ display: "inline-block", width: 336, height: 280}}
  format=''
/>
</div>
<hr />
                            <div className="sn-content">
                                

                                <h5 style={{ marginBottom: '20px' }}><b>{(props.reviewsData.reviews.length+reviews.length)>0?"Reviews":"No reviews"}</b> <AddReview app={props.reviewsData.app} onAdd={onAddReview} /> </h5>
                                
                                {reviews.map((elem, idx)=> {
                                    return <Review data={elem} key={idx} />;
                                })}

                                {props.reviewsData.reviews.map((elem, idx)=> {
                                    return <Review data={elem} key={idx} />;
                                })}

                            </div>
                        </div>
                    </div>
                    
                    <RecentRandom />

                </div>
            </div>
        </div>
        {/* Single News End*/}

    </>);
}