import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Paginator from './Paginator';
import ApkCityAPI from '../services/ApkCityAPI';
import FakeA from './FakeA';
import {useHistory, useParams} from 'react-router-dom';
import { BounceLoader } from "react-spinners";

let apkCityAPI = ApkCityAPI.getInstance();

const perPage = 1000;

export default function Archive() {
    let history = useHistory();
    let {pageNumber} = useParams();

    let [appCount, setAppCount] = useState(null);
    let [pageCount, setPageCount] = useState(0);
    let [currentPage, setCurrentPage] = useState(typeof pageNumber !== 'undefined' ?pageNumber:1);
    let [entries, setEntries] = useState(null);

    useEffect(()=> {
        if (pageNumber) {
            setCurrentPage(pageNumber);
        }

    }, [pageNumber]);

    useEffect(()=> {
        (async ()=> {
            let res = await apkCityAPI.getAppCount();
            setAppCount(res.appCount);
            setPageCount(res.appCount / perPage);
            console.log(res);
        })();
    }, []);

    useEffect(()=> {
        (async ()=> {
            setEntries(null);

            let res = await apkCityAPI.getAppsPage((currentPage-1) * perPage, perPage);

            setEntries( res.result );

        })();

    }, [currentPage]);

    function onPageSelect(page) {
        // setCurrentPage(page);


        history.push('/archive/'+page);
    }

    return (<>

        <Helmet>
            <title>Archive - apkzz.com</title>
            <meta name="description" content="Full list of mobile apps and games saved in our database. Explore the biggest collection of applications, check all categories and authors." />

        </Helmet>
        <div style={{ backgroundColor: '#F3F3FF', paddingTop: '25px' }}>
            <div style={{ maxWidth: '1220px' }} className="container">
                <div className="row align-items-center">
                    <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '25px' }} className="col-lg-8 mx-auto">
                        <center>
                            <br />
                            <h3>Archive</h3>
                            <br />


                            {entries === null && <>
                                <div style={{ width: '100%', height: '100px', textAlign: 'center', paddingTop: '20px' }}>
                                    <BounceLoader />
                                </div>
                            </>}

                            {entries !== null && entries.map((elem, idx)=> {

                                return <React.Fragment key={idx}>
                                    <FakeA href={'/'+elem.slug + '-apk-update-unlocked'}>{elem.title}</FakeA><br />
                                    </React.Fragment>;
                            })}
                            
                            
                            <br />
                            {/* <a href={1}><button className="btn" type="submit">1</button></a>
                            <a href={2}><button className="btn" type="submit">2</button></a>
                            <a href={3}><button className="btn" type="submit">3</button></a>
                            <a href={4}><button className="btn" type="submit">4</button></a>
                            <a href={5}><button className="btn" type="submit">5</button></a>
                            <a href={6}><button className="btn" type="submit">6</button></a>
                            <a href={7}><button className="btn" type="submit">7</button></a>
                            <a href={8}><button className="btn" type="submit">8</button></a>
                            <a href={9}><button className="btn" type="submit">9</button></a>
                            <a href={10}><button className="btn" type="submit">10</button></a> */}

                            <Paginator 
                                prelink='/archive/'
                                onPageSelect={onPageSelect}
                                pageCount={pageCount} currentPage={currentPage} />
                            <br /><br />
                        </center>
                    </div>
                </div>
            </div>
        </div>

    </>);
}