import {Helmet} from 'react-helmet';

export default function Error404() {

    return (<>
        <Helmet>
            <title>Error 404 - apkzz.com</title>
            <meta name="description" content="Sorry, this page does not exist. Go back to homepage and try again." />
        </Helmet>
        <div style={{ backgroundColor: '#F3F3FF', paddingTop: '25px' }}>
            <div style={{ maxWidth: '1220px' }} className="container">
                <div className="row align-items-center">
                    <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '25px' }} className="col-lg-8">
                        <br />
                        <h3>Page not found</h3>
                        <br /> <br />
                        Sorry, this page does not exist. Go back to homepage and try again. <br /><br />
                        <br />
                    </div>
                    <div style={{ marginBottom: '25px' }} className="col-lg-4">
                        <div style={{ backgroundColor: '#fff', border: '3px solid #EEEEFE', marginLeft: '7px' }}>
                            <br />
                            <center>
                                <img alt="" style={{ maxWidth: '100%' }} src="img/404.png" />
                            </center>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}