import {useState} from 'react';

export default function Pills(props) {

    let {options} = props;
    
    if (options === undefined)
        options = ["Reviews", "Random", "Download"];

    let [selected, setSelected] = useState(options[0]);
    
    function emitSelected(elem) {
        if (props.onSelect) {
            props.onSelect(elem);
        }
    }

    return (<>
        <ul className="nav nav-pills nav-justified">

            {options.map((elem, idx) => {
                let classN = "nav-link clickable";
                if (elem === selected) classN+= " active";

                return <li key={idx} className="nav-item"
                    onClick={(e)=>{

                        setSelected(elem);
                        emitSelected(elem);
                    }}
                >
                    <a className={classN} data-toggle="pill" >{elem}</a>
                </li>;
            })}


        </ul>
    </>);
}