import {Helmet} from 'react-helmet';

export default function Contact() {

    return (<>
        <Helmet>
            <title>Contact us - apkzz.com</title>
            <meta name="description" content="Want to report an abuse? Wanna ask a question or make suggestion? Reach us via email and wait 24 hours for the response." />

        </Helmet>
        {/* Contact Start */}
        <div style={{backgroundColor: '#F3F3FF'}} className="contact">
          <div style={{maxWidth: '1220px'}} className="container">
            <div className="row align-items-center">
              <div style={{backgroundColor: '#fff', border: '3px solid #EEEEFE', marginBottom: '20px'}} className="col-lg-8">
                <br />   
                <h3>Contact</h3>	
                <br /> 
                Want to report an abuse? Wanna ask a question or make suggestion? Do you have a proposal for us? Reach us via email and wait 24 hours for the response.
                <br /><br /><br />  
                <center>
                  <h4><b><font color="62D67C">apkzzdotcom@gmail.com</font></b></h4>
                </center>
                <br /><br />  
                Remember to include the reason for your contact in the subject line. Example subjects: report a problem, DMCA takedown request, comments and feedback, developer support.
                <br /><br />
              </div>
              <div style={{marginBottom: '15px'}} className="col-lg-4">
                <div style={{backgroundColor: '#fff', border: '3px solid #EEEEFE', marginLeft: '7px'}}>   
                  <br />  
                  <center>		
                    <img alt=""  style={{maxWidth: '100%'}} src="img/contact.jpg" />
                  </center> 
                  <br /> 
                </div>        
              </div>
            </div>
          </div>
        </div>
        {/* Contact End */}
        </>);
}