import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ApkCityAPI from '../services/ApkCityAPI';
import Review from './Review';
import RecentRandom from './RecentRandom';
import slug from 'slug';
import Rating from './Rating';
import FakeA from './FakeA';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";
import { beautifyText } from "./Misc";
import Alert from 'react-bootstrap/Alert';
let apkCityAPI = ApkCityAPI.getInstance();

let wwwregexp = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gm;

export default function AddReview(props) {

    let [addingReview, setAddingReview] = useState(false);
    let [review, setReview] = useState("");
    let [nickname, setNickname] = useState("");
    let [captcha, setCaptcha] = useState("");
    let [rating, setRating] = useState(5);

    async function submitReview() {
        let rev = beautifyText(review.trim());


        if (review.search(wwwregexp) !== -1 || nickname.search(wwwregexp) !== -1) {
            alert('You can\'t use URL in review or your nickname!');
            return;
        }
        if (rev === '') {
            alert('Review content is required!');
            return;
        }

        let n = beautifyText(nickname.trim());

        if (n === '') {
            alert('nickname is required!');
            return;
        }

        if (!captcha) {
            alert('Solve captcha!');
            return;
        }

        let body = {
            'g-recaptcha-response': captcha,
            review: rev,
            nickname: n,
            appID: props.app.appID,
            rating
        }

        //console.log(body);

        let result = await apkCityAPI.saveReview(body);

        if (props.onAdd) {
            props.onAdd(result.data);
        }

        setAddingReview(0);
        setNickname("");
        setReview("");
        setRating(5);
    }


    return (<>

        {addingReview == false && <button onClick={(e) => { setAddingReview(true); }} style={{ float: 'right' }} className="btn" type="submit">Write Review</button>}

        {addingReview == true &&
            <div style={{ paddingTop: '25px' }}>
                <div style={{ maxWidth: '1220px' }} className="container">
                    <div className="row align-items-center" style={{ marginBottom: '25px' }} >
                        <br />
                        <h5><b>Write Review</b></h5>
                        <br />
                        <textarea value={review} onChange={(e) => { setReview(e.target.value) }} className="form-control" rows={5} placeholder="Review..." />
                        <div className="row">
                            <div className="col-md-6">
                                <input value={nickname} onChange={(e) => { setNickname(e.target.value) }} type="text" className="form-control" placeholder="Nickname..." />
                            </div>
                            <div className="col-md-6">
                                <Rating score={rating} />
                                <p style={{ margin: '2px' }}> <input type="range" id="cowbell" name="cowbell"
                                    min="0" max="5" value={rating} onChange={(e) => { setRating(e.target.value); }} step="0.5" /> </p>

                            </div>
                        </div>
                        <br />
                        <center> <ReCAPTCHA
                            style={{ margin: '5px' }}
                            sitekey="6Lf1GssbAAAAAIJpy_blj-luckLgFG0CK7L6jE9V"
                            onChange={(value) => { setCaptcha(value); }}
                        />

                            <button onClick={(e) => { submitReview() }} className="btn" type="submit">Submit</button>
                        </center>
                        <br />
                    </div>
                </div>
            </div>}

    </>);
}